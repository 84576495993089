import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderFive";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import dataNav from "../../data/Navbar/navbar-data.json";

const Highlights = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get("https://in.maroof.com.pk/webservices/rhodium/get.php");
        if (response.data.status === "success") {
          setVideos(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <Loader>
      <HeaderOne data={dataNav} />
      <PageTitleAbout title="Construction Updates" tagline="Rhodium Residencia | Luxury Apartments" />
      <Box py={5} my={5} className="container">
        {loading ? (
          <Typography textAlign="center">Loading updates...</Typography>
        ) : (
          <Grid container spacing={4}>
            {videos.map((video, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {video.name}
                    </Typography>
                    <iframe
                      width="100%"
                      height="300"
                      src={video.link.replace("youtu.be/", "www.youtube.com/embed/")}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <ClientsBrand />
      <FooterOne />
    </Loader>
  );
};

export default Highlights;
