import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderFive";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import { Box, Grid, Typography, Card, CardContent, TextField, Button } from "@mui/material";
import dataNav from "../../data/Navbar/navbar-data.json";

const ConstructionUpdates = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      const response = await axios.get("https://in.maroof.com.pk/webservices/rhodium/get.php");
      if (response.data.status === "success") {
        setVideos(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (password !== "rhodium@123!") {
      setMessage("Wrong password");
      return;
    }

    try {
      const response = await axios.post("https://in.maroof.com.pk/webservices/rhodium/insert.php", {
        name,
        link,
      });

      if (response.data.status === "success") {
        setMessage("Video added successfully");
        setName("");
        setLink("");
        setPassword("");
        fetchVideos();
      } else {
        setMessage("Failed to add video");
      }
    } catch (error) {
      setMessage("Error adding video");
    }
  };

  return (
    <Loader>
      <HeaderOne data={dataNav} />
      <PageTitleAbout title="Construction Updates" tagline="Rhodium Residencia | Luxury Apartments" />
      <Box py={5} my={5} className="container">
        <Box mb={4}>
          <TextField fullWidth label="Video Name" value={name} onChange={(e) => setName(e.target.value)} />
          <TextField fullWidth label="Video Link" value={link} onChange={(e) => setLink(e.target.value)} sx={{ my: 2 }} />
          <TextField fullWidth type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
            Submit
          </Button>
          {message && (
            <Typography color="error" mt={2}>
              {message}
            </Typography>
          )}
        </Box>
        {loading ? (
          <Typography textAlign="center">Loading updates...</Typography>
        ) : (
          <Grid container spacing={4}>
            {videos.map((video, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {video.name}
                    </Typography>
                    <iframe
                      width="100%"
                      height="300"
                      src={video.link.replace("youtu.be/", "www.youtube.com/embed/")}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <ClientsBrand />
      <FooterOne />
    </Loader>
  );
};

export default ConstructionUpdates;
